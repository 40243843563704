import React from 'react';
import { ThemeProvider } from 'styled-components';
import ScrollToTop from 'react-scroll-up';
import Navbar from './navbar/navbar';
import Footer from './footer/footer';
import ScrollUpButton from './scroll-up-button/scroll-up-button';
import ResetCss from './reset-css';
import { theme } from '../theme';
import Helmet from 'react-helmet'

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>

{/* 
<script async src="https://www.googletagmanager.com/gtag/js?id=G-EQFYL1R01C"></script>
<script>
{`
        window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-EQFYL1R01C');
  `}
</script>
 */}

      </Helmet>
      {/* <DevHeader /> */}
        <ResetCss />
        <div top={0} innerz={9999} activeclass="nav-sticky">
          <Navbar />
          {/* <Header /> */}

        </div>
        <div id="t"></div>

        {children}

        <Footer>
          Copyright &copy; {new Date().getFullYear()}
          <a href="https://www.teikokushoin.co.jp/"> 帝国書院</a>
          <span> ＆ </span>
          <a href="https://www.kashika.or.jp/"> 一般社団法人 都市構造可視化推進機構</a>
        </Footer>
        <ScrollToTop
          showUnder={300}
          duration={700}
          easing="easeInOutCubic"
          style={{ bottom: 30, right: 20 }}
        >
          <ScrollUpButton />
        </ScrollToTop>
    </ThemeProvider>
  );
};

export default Layout;
